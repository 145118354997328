import React from 'react';

import { BasePage } from '../components/BasePage';
import { ContentCard } from '../components/ContentCard';

// tslint:disable-next-line:no-var-requires
require("./index.less");

interface HomePageProps extends React.HTMLProps<HTMLDivElement> { }
interface HomePageState extends React.HTMLProps<HTMLDivElement> {
    seed: number,
}


export default class HomePage extends React.Component<HomePageProps, HomePageState> {

    constructor(props) {
        super(props);
        this.state = {seed: Date.now()}
    }


    _key = 0
    get key () {
        return "INDEXKEY" + (this._key++).toString()
    }
    
    public render() {
        return <BasePage key="Main Page" children={[
            <ContentCard key={this.key} style={{textAlign:"center"}} children={[
                {type: "Heading1", content: "COMING SOON (MAYBE)"}
            ]} />
        ]} />
    }
}